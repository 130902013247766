import React from "react";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import MainFeature from "components/features/OurActivity.js";
import TabGrid from "components/cards/Items.js";
import Footer from "components/footers/Footer.js";

const Container = tw.div`relative`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const { subpage } = useParams();

  const cards = [
    {
      activity: "leisures",
      title: "Alone or in a group",
    },
    {
      activity: "ticketing",
      title: "Concert, Sport",
    },
    {
      activity: "restauration",
      title: "Classic, Gourmet",
    },
  ];

  const currentCard = cards.find((card) => card.activity === subpage);

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <MainFeature
          subheading={<Subheading>Established Since 2014</Subheading>}
          heading={
            <>
              {currentCard.title}
              <wbr /> <HighlightedText>{currentCard.activity}</HighlightedText>
            </>
          }
          description={
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br />
              <br />
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Description>
          }
          buttonRounded={false}
          textOnLeft={true}
          primaryButtonText="Latest Offers"
          imageSrc={
            "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
          }
          imageCss={imageCss}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        />

        <TabGrid
          heading={
            <>
              Discover our <HighlightedText> offers</HighlightedText>
            </>
          }
        />

        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
