import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import OurService from "components/features/OurService.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-justify`;

const Content = tw.div`mt-16`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

  const cards = [
    {
      imageSrc: "/images/items/pic800x600_mobility.png",
      subtitle: "Assistance and Planning",
      title: (
        <>
          Mobility & <span tw="text-primary-500">Travels</span>
        </>
      ),
      description: (
        <>
          <span tw="font-bold text-primary-500">TRIPnBOX</span> is your trusted
          partner in simplifying your transportation and making your travel
          experience exceptional. We take pride in handling all logistical
          aspects of your travel by carefully coordinating all your transfers,
          whether it's airport shuttles, inter-city travel, or private chauffeur
          services. We understand the importance of hassle-free travel, which is
          why we are committed to providing personalized, punctual, and secure
          service. Let us take care of the logistical details so you can fully
          focus on your journey.
        </>
      ),
      textOnLeft: false,
      url: "/services/mobility",
    },
    //    {
    //      imageSrc: "/images/items/pic800x600_ticketing.png",
    //      subtitle: "Assistance et Plannification",
    //      title: (
    //        <>
    //          Billetteries & <span tw="text-primary-500">Activités</span>
    //        </>
    //      ),
    //      description:
    //        "TRIPnBOX excelle dans la réservation de billets pour une vaste gamme d'événements et d'activités de loisir, faisant de votre séjour une expérience mémorable. Que vous ayez l'intention d'assister à un concert envoûtant, à un spectacle captivant, à un événement sportif palpitant ou à toute autre forme de divertissement pendant votre séjour, notre équipe dévouée est à vos côtés pour simplifier l'intégralité du processus. Profiter pleinement de chaque instant sans vous préoccuper de l'organisation logistique et transformer vos loisirs en souvenirs mémorables en nous laissant prendre en charge tous les détails.",
    //      textOnLeft: false,
    //      url: "https://timerse.com",
    //    },
    {
      imageSrc: "/images/items/pic800x600_accommodations.png",
      subtitle: "Assistance and Planning",
      title: (
        <>
          Accommodations & <span tw="text-primary-500">Stays</span>
        </>
      ),
      description: (
        <>
          <span tw="font-bold text-primary-500">TRIPnBOX</span> is your
          accommodation expert, whether you're considering an overnight getaway
          or an extended stay. We specialize in booking unique places, offering
          a range from classic hotels to more unconventional experiences. Our
          dedicated team is committed to finding the perfect place that meets
          your needs and desires. Whether it's an elegant hotel, a picturesque
          vacation home, or an out-of-the-ordinary option, we're here to make
          your stay as comfortable as it is memorable. Entrust us with the
          logistics of your accommodation and fully enjoy your vacation.
        </>
      ),
      textOnLeft: true,
      url: "/services/accomodations",
    },
    {
      imageSrc: "/images/items/pic800x600_restauration.png",
      subtitle: "Assistance and Planning",
      title: (
        <>
          Dining & <span tw="text-primary-500">Gastronomy</span>
        </>
      ),
      description: (
        <>
          <span tw="font-bold text-primary-500">TRIPnBOX</span> can provide you
          with an exceptional culinary experience in terms of dining and
          gastronomy. Whether you're planning a short getaway or an extended
          stay, we will assist you in booking unique venues offering a range of
          flavors from classic gastronomy delights to more original culinary
          experiences. Our dedicated team is committed to finding the perfect
          gastronomic spots that match your culinary preferences and desires.
          Entrust us with planning your meals and dive into a culinary journey
          where each bite is an invitation to discovery and wonder.
        </>
      ),
      textOnLeft: false,
      url: "/services/restauration",
    },
  ];

  return (
    <>
      <Container>
        <SingleColumn>
          <HeadingInfoContainer>
            <HeadingTitle>
              Discover our <span tw="text-primary-500">tailored services</span>
            </HeadingTitle>
            <HeadingDescription>
              Discover the excellence of our customized services at TRIPnBOX. We
              are your trusted partner for exceptional travel experiences,
              offering a comprehensive range of personalized services. Whether
              it's organizing your travels with various transportation options
              (train, plane, car), planning memorable leisure activities
              (events, concerts, sports, cultural visits), or ensuring
              accommodations tailored to your needs, we are here to simplify
              every step of your journey. Our commitment to customer
              satisfaction and our expertise in the industry make TRIPnBOX the
              ideal choice for perfectly orchestrated trips. Trust us to turn
              your travel plans into unforgettable memories.
            </HeadingDescription>
          </HeadingInfoContainer>

          <Content>
            {cards.map((card, i) => (
              <OurService
                subheading={<Subheading>{card.subtitle}</Subheading>}
                heading={card.title}
                description={card.description}
                primaryButtonText={"Learn More"}
                primaryButtonUrl={card.url}
                imageSrc={card.imageSrc}
                textOnLeft={card.textOnLeft}
              />
            ))}
          </Content>
        </SingleColumn>

        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>
    </>
  );
};
