import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import OursActivities from "components/features/OursActivities.js";
import Footer from "components/footers/Footer.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-justify`;

const Content = tw.div`mt-16`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  const cards = [
    {
      activity: "leisures",
      imageSrc: "/images/items/pic800x600_leisures.png",
      title: "Activities",
      title2: "Leisures",
      subtitle: "Assistance and Booking",
      description: (
        <>
          Discover the array of{" "}
          <span tw="font-bold text-primary-500">thrilling activities</span>{" "}
          awaiting adventure enthusiasts during your stay. From water pleasures
          such as kayaking, diving, and{" "}
          <span tw="font-bold text-primary-500">jet-skiing</span> inviting you
          to explore crystal-clear waters with wonder, to nature lovers' scenic
          hikes through enchanting trails offering an unforgettable communion
          with the surrounding natural beauty.
          <br />
          For those who prefer the adrenaline rush of{" "}
          <span tw="font-bold text-primary-500">motor sports</span>, unique
          experiences like quad biking, buggy rides, or circuit driving are
          within reach. These activities promise exhilarating moments and{" "}
          <span tw="font-bold text-primary-500">unforgettable memories</span>{" "}
          for speed enthusiasts.
          <br />
          Whether you choose to embark on these experiences alone, as a couple,
          or in a group, each adventure becomes an opportunity to share powerful
          moments and create common memories. Set off for days filled with
          discoveries, challenges, and laughter, where teamwork and camaraderie
          blend with the{" "}
          <span tw="font-bold text-primary-500">passion for travel</span>.
        </>
      ),
      buttonText: "Discover our offers",
      buttonUrl: "/activity/leisures",
    },
    {
      activity: "ticketing",
      imageSrc: "/images/items/pic800x600_ticketing.png",
      title: "Events",
      title2: "Ticketing",
      subtitle: "Assistance and Booking",
      description: (
        <>
          Explore an exceptional dimension of entertainment during your stay by
          having the opportunity to attend{" "}
          <span tw="font-bold text-primary-500">
            concerts of your favorite artists
          </span>
          . Immerse yourself in the enchanting atmosphere of live performances,
          resonate with the rhythm of the music, and create unforgettable
          memories surrounded by your favorite melodies.
          <br />
          For sports enthusiasts, treat yourself to the opportunity{" "}
          <span tw="font-bold text-primary-500">
            to attend matches and other sporting events
          </span>{" "}
          of your favorite teams. Feel the electric energy of the stadiums,
          cheer passionately for your team, and share moments of victory and
          emotion with other enthusiastic fans.
          <br />
          Whether solo, as a duo, or in a group, these artistic and sporting
          experiences transcend mere observation to become shared moments marked
          by excitement, camaraderie, and enjoyment.{" "}
          <span tw="font-bold text-primary-500">Embrace every moment</span> and
          transform your stay into an adventure full of cultural discoveries and
          a passion for music and sports.
        </>
      ),
      buttonText: "Discover our offers",
      buttonUrl: "/activity/ticketing",
    },
    /*    {
      activity: "restauration",
      imageSrc: "/images/items/pic800x600_restauration.png",
      title: "Classic, Gourmet",
      title2: "RESTAURATION",
      subtitle: "Assistance and Booking",
      description: (
        <>
          Explore an exceptional dimension of entertainment during your stay by
          having the opportunity to attend{" "}
          <span tw="font-bold text-primary-500">
            concerts of your favorite artists
          </span>
          . Immerse yourself in the enchanting atmosphere of live performances,
          resonate with the rhythm of the music, and create unforgettable
          memories surrounded by your favorite melodies.
          <br />
          For sports enthusiasts, treat yourself to the opportunity{" "}
          <span tw="font-bold text-primary-500">
            to attend matches and other sporting events
          </span>{" "}
          of your favorite teams. Feel the electric energy of the stadiums,
          cheer passionately for your team, and share moments of victory and
          emotion with other enthusiastic fans.
          <br />
          Whether solo, as a duo, or in a group, these artistic and sporting
          experiences transcend mere observation to become shared moments marked
          by excitement, camaraderie, and enjoyment.{" "}
          <span tw="font-bold text-primary-500">Embrace every moment</span> and
          transform your stay into an adventure full of cultural discoveries and
          a passion for music and sports.
        </>
      ),
      buttonText: "Discover our offers",
      buttonUrl: "/activity/restauration",
    },
  */
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <SingleColumn>
          <HeadingInfoContainer>
            <HeadingTitle>
              Exceptional Activities, <br />
              <span tw="text-primary-500">Explore a World of Experiences</span>
            </HeadingTitle>
            <HeadingDescription>
              With <span tw="font-bold text-primary-500">TRIPnBOX</span> explore
              exceptional experiences, whether you're traveling solo or in a
              group. Enjoy privileged access to unforgettable events such as
              enchanting concerts and thrilling sports encounters. Immerse
              yourself in personalized activities, whether they are nautical,
              focused on mechanical sports, in the heart of nature, or other
              exciting adventures. Designed to cater to your tastes and desires,
              these unique experiences offer you the freedom to choose how to
              live your journey, whether alone or surrounded by travel
              companions.
              <br /> <br />
              In addition to these memorable moments, TRIPnBOX invites you to
              discover unique gastronomy. From Michelin-starred restaurants to
              local culinary gems, dive into exceptional gastronomic experiences
              that add a delightful touch to your journey. Whether you want to
              savor a meal in solitude or share culinary delights with friends,
              each experience is crafted to awaken your taste buds and create
              memories that last a lifetime. Embark with us on a journey where
              each activity, event, and meal becomes a unique adventure to be
              fully enjoyed, alone or accompanied.
            </HeadingDescription>
          </HeadingInfoContainer>

          <Content>
            {cards.map((card, i) => (
              <OursActivities
                subheading={<Subheading>{card.subtitle}</Subheading>}
                heading={
                  <>
                    {card.title}
                    <wbr /> <HighlightedText>{card.title2}</HighlightedText>
                  </>
                }
                description={<Description>{card.description}</Description>}
                buttonRounded={false}
                textOnLeft={false}
                primaryButtonText={card.buttonText}
                primaryButtonUrl={card.buttonUrl}
                imageSrc={card.imageSrc}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
              />
            ))}
          </Content>
        </SingleColumn>

        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
