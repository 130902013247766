import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import OursDestinations from "components/features/OursDestinations.js";
import Footer from "components/footers/Footer.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-justify`;

const Content = tw.div`mt-16`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  const cards = [
    {
      destination: "spain",
      imageSrc: "/images/countries/country800x600_ES.png",
      subtitle: "Vibrant, Cultural, Sunny",
      country: "SPAIN",
      city: "Madrid, Barcelona, Valencia, Seville...",
      description:
        "Spain, jewel of the Iberian Peninsula, enchants visitors with its diversity and irresistible charm. This Mediterranean country, bathed in sunshine, seduces with its golden beaches, vibrant cities, and rich cultural heritage. From the lively, cobbled streets of Barcelona to the artistic effervescence of Madrid, every corner of Spain tells a captivating story. The intense flavors of Spanish cuisine, the passion of flamenco, and iconic landmarks like the Alhambra in Granada add a unique dimension to this enchanting experience. Come and discover Spain, where each moment reveals an exquisite blend of tradition and modernity.",
      buttonText: "Discover offers",
      buttonUrl: "/destination/spain",
    },
    {
      destination: "france",
      imageSrc: "/images/countries/country800x600_FR.png",
      subtitle: "Romantic, Historic, Gastronomic",
      country: "FRANCE",
      city: "Paris, Marseille, Bordeaux, Lyon...",
      description:
        "France, the land of elegance and charm, dazzles visitors with its captivating diversity. From the cobblestone streets of Paris, the city of lovers, to the picturesque vineyards of the countryside, every corner of France reveals a rich history and timeless beauty. Iconic landmarks like the Eiffel Tower and the Louvre bear witness to its historical heritage, while delightful French cuisine invites to unforgettable feasts. From medieval hilltop villages to the sunny beaches of the French Riviera, France offers an enchanting experience where art, culture, and the joy of living harmoniously blend. Come and discover France, where every moment is a celebration of the art of French living.",
      buttonText: "Discover offers",
      buttonUrl: "/destination/france",
    },
    {
      destination: "italy",
      imageSrc: "/images/countries/country800x600_IT.png",
      subtitle: "Artistic, Passionate, Picturesque",
      country: "ITALY",
      city: "Rome, Milan, Naples, Venice...",
      description:
        "Italy, the jewel of Europe, captivates with its artistic splendor, overflowing passion, and picturesque landscapes. From the majestic ruins of Rome to the romantic canals of Venice, every corner of Italy breathes history and art. The green hills of Tuscany, adorned with vineyards, add a touch of picturesque charm to this enchanting canvas. Italian passion, palpable in delicious cuisine and expressive gestures, brings every experience to life. Italy is a journey through the centuries, where art, romance, and gastronomy blend to create an unforgettable symphony. Come immerse yourself in the passionate soul of Italy, where every moment is a work of art.",
      buttonText: "Discover offers",
      buttonUrl: "/destination/italy",
    },
    {
      destination: "portugal",
      imageSrc: "/images/countries/country800x600_PT.png",
      subtitle: "Charming, Coastal, Cultural",
      country: "PORTUGAL",
      city: "Lisbonne, Porto, Vila Nova de Gaia...",
      description:
        "Portugal, the gem of the Iberian Peninsula, captivates with its enchanting charm, picturesque coastlines, and rich cultural heritage. From the cobblestone streets of Lisbon to the rolling vineyards of the Douro Valley, every corner of Portugal tells a warm and authentic story. The golden beaches of the Algarve offer an unforgettable seaside getaway, while medieval citadels in the hills add a touch of mystery to this enchanting landscape. Portuguese cuisine, with its Mediterranean flavors and seafood delights, delights the taste buds with an unparalleled gastronomic experience. Come discover Portugal, where warm hospitality and breathtaking landscapes welcome you at every turn, making every stay a memorable adventure.",
      buttonText: "Discover offers",
      buttonUrl: "/destination/portugal",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <SingleColumn>
          <HeadingInfoContainer>
            <HeadingTitle>
              Destinations of Dream, <br />
              <span tw="text-primary-500">Discover unique places</span>
            </HeadingTitle>
            <HeadingDescription>
              With <span tw="font-bold text-primary-500">TRIPnBOX</span> dive
              into the world of exceptional journeys by entrusting your dreams
              to our dedicated team of experts, passionate about the art of
              creating unforgettable experiences. Immersed in a profound
              knowledge of terrains across Europe, we commit to surpassing your
              expectations by offering much more than a simple getaway.
              <br /> <br />
              Our organizational process is built on a meticulous understanding
              of each destination, exploring its cultural nuances, rich history,
              and distinctive charm. Whether you dream of wandering through the
              medieval alleys of a historic city, marveling at architectural
              treasures, or relaxing on pristine beaches, we carefully craft
              each itinerary to create an immersive and authentic experience,
              without the slightest flaw.
              <br /> <br />
              In addition to these unique experiences, we have the power to open
              doors to rare events, such as highly sought-after concerts and
              exclusive sporting encounters. Picture yourself as a VIP,
              resonating to the beats of music in prestigious venues or
              witnessing sporting moments up close. With us, every journey
              becomes an opportunity to live extraordinary moments and create
              unique memories. Join us on an adventure where each destination is
              a gateway to unparalleled experiences.
            </HeadingDescription>
          </HeadingInfoContainer>

          <Content>
            {cards.map((card, i) => (
              <OursDestinations
                subheading={<Subheading>{card.city}</Subheading>}
                heading={
                  <>
                    {card.subtitle}
                    <wbr /> <HighlightedText>{card.country}</HighlightedText>
                  </>
                }
                description={<Description>{card.description}</Description>}
                buttonRounded={false}
                textOnLeft={false}
                primaryButtonText={card.buttonText}
                primaryButtonUrl={card.buttonUrl}
                imageSrc={card.imageSrc}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
              />
            ))}
          </Content>
        </SingleColumn>

        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
