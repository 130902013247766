import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>1. Introduction</h1>
            <p>
              Welcome to TRIPnBOX's Privacy Policy. At TRIPnBOX, we value your
              privacy and are committed to protecting your personal information.
              This Privacy Policy outlines how we collect, use, disclose, and
              safeguard your information when you use our website and services.
            </p>
            <h2>2. Information We Collect</h2>
            <h3>2.1 Personal Information</h3>
            <p>
              When you make a reservation, subscribe to our newsletter, or
              interact with our website, we may collect personal information
              such as your name, email address, phone number, billing
              information, and other details relevant to your booking.
            </p>
            <h3>2.2 Usage Data</h3>
            <p>
              We may also collect information about how you interact with our
              website, including your IP address, browser type, device type,
              pages visited, and other usage data. This information helps us
              improve our website and provide a better user experience.
            </p>
            <h3>2.3 Cookies</h3>
            <p>
              We use cookies and similar tracking technologies to enhance your
              browsing experience and personalize content. You can control
              cookies through your browser settings, but please note that
              disabling cookies may affect your ability to access certain
              features of our website.
            </p>
            <h2>3. How We Use Your Information</h2>
            <p>
              We use the information we collect for various purposes, including:
            </p>
            <ul>
              <li>Processing and confirming your reservations</li>
              <li>Communicating with you about your bookings</li>
              <li>Providing customer support</li>
              <li>Improving our website and services</li>
              <li>Sending promotional offers and updates</li>
            </ul>
            <h2>4. Information Sharing and Disclosure</h2>
            <p>
              We may share your personal information with third-party service
              providers who assist us in operating our website, conducting our
              business, or servicing you. These service providers are
              contractually obligated to use your information only as necessary
              to provide the services requested by TRIPnBOX.
            </p>
            <h2>5. Data Security</h2>
            <p>
              We take the security of your personal information seriously and
              implement appropriate measures to protect it from unauthorized
              access, alteration, disclosure, or destruction. However, please be
              aware that no method of transmission over the internet or
              electronic storage is 100% secure.
            </p>
            <h2>6. Your Rights</h2>
            <p>
              You have the right to access, correct, or delete your personal
              information at any time. You may also unsubscribe from our
              promotional communications by following the instructions provided
              in the emails we send. If you have any questions or concerns about
              your privacy rights, please contact us using the information
              provided below.
            </p>
            <h2>7. Changes to this Privacy Policy</h2>
            <p>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be effective immediately upon posting
              on our website. We encourage you to review this Privacy Policy
              periodically for any updates.
            </p>
            <h2>8. Contact Us</h2>
            <p>
              If you have any questions, comments, or concerns about this
              Privacy Policy or our privacy practices, please contact us at
              privacy@tripnbox.com.
            </p>
            <p>Effective Date: 2023-04-11</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
