import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import { ReactComponent as AtMail } from "feather-icons/dist/icons/at-sign.svg";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition of Sale" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>1. Purpose</h1>
            <p>
              These Terms and Conditions of Sale (TCS) govern the contractual
              relationships between TRIPnBOX, hereinafter referred to as
              "TRIPnBOX," and any natural or legal person wishing to benefit
              from the services offered by TRIPnBOX, hereinafter referred to as
              "the Client."
            </p>
            <h1>2. Booking and Payment</h1>
            <h2>2.1 Booking Terms</h2>
            <p>
              The Client can make a reservation for travel or experiences via
              the TRIPnBOX website, by phone, or by email. The reservation is
              subject to availability and confirmation by TRIPnBOX.
            </p>
            <h2>2.2 Payment Terms</h2>
            <p>
              Payment for reserved services is made according to the terms
              agreed upon between TRIPnBOX and the Client. Prices displayed on
              the TRIPnBOX website are in euros and include all applicable taxes
              unless otherwise indicated.
            </p>
            <h2>2.3 Reservation Confirmation</h2>
            <p>
              The reservation is considered confirmed only upon receipt of full
              payment or the required deposit by TRIPnBOX.
            </p>
            <h1>3. Modification and Cancellation</h1>
            <h2>3.1 Reservation Modification</h2>
            <p>
              Any request to modify a reservation must be addressed to TRIPnBOX
              and is subject to availability and acceptance by TRIPnBOX.
              Modification fees may apply.
            </p>
            <h2>3.2 Reservation Cancellation</h2>
            <p>
              In case of cancellation by the Client, cancellation fees may be
              charged in accordance with the specific reservation conditions
              established by TRIPnBOX.
            </p>
            <h1>4. Responsibilities</h1>
            <h2>4.1 TRIPnBOX's Responsibility</h2>
            <p>
              TRIPnBOX undertakes to provide the reserved services with
              diligence and professionalism. However, TRIPnBOX disclaims all
              liability for damages, losses, or accidents occurring during the
              reserved travel or experience.
            </p>
            <h2>4.2 Travel Insurance</h2>
            <p>
              It is strongly recommended that the Client take out travel
              insurance covering the risks associated with their trip. This
              insurance may include, but is not limited to, coverage for medical
              expenses, trip cancellation, emergency repatriation, lost or
              damaged luggage, and other unforeseen events. The Client is solely
              responsible for obtaining adequate travel insurance and verifying
              the coverage provided by their insurer.
            </p>
            <h2>4.3 Limitation of Liability</h2>
            <p>
              To the extent permitted by law, TRIPnBOX disclaims all liability
              for indirect, incidental, special, or consequential damages
              arising from the use of the services provided, including, but not
              limited to, loss of revenue, loss of profits, loss of data, or
              property damage. In no event shall TRIPnBOX's total liability to
              the Client exceed the total amount paid by the Client for the
              reserved services.
            </p>
            <h1>5. Protection of Personal Data</h1>
            <h2>5.1 Data Collection and Processing</h2>
            <p>
              TRIPnBOX undertakes to collect and process the Client's personal
              data in accordance with applicable data protection regulations.
            </p>
            <h2>5.2 Data Retention</h2>
            <p>
              The Client's personal data is securely and confidentially stored
              and used only for the provision of reserved services.
            </p>
            <h1>6. Applicable Law and Jurisdiction</h1>
            <p>
              These TCS are governed by French law. Any dispute relating to
              these TCS shall be the exclusive jurisdiction of the French
              courts.
            </p>
            <h1>7. Modification of TCS</h1>
            <p>
              TRIPnBOX reserves the right to modify these TCS at any time.
              Clients will be informed of any substantial changes to the TCS.
            </p>
            <p>
              By accepting these TCS, the Client acknowledges having read and
              accepted all the provisions contained therein.
            </p>
            <p>Done in Geneve, on 2023.04.11</p>
            <h1>Contact Us</h1>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <ul>
              <li>
                By email: hello
                <span style={{ display: "inline-block" }}>
                  <AtMail style={{ width: "16px", height: "16px" }} />
                </span>
                tripnbox.com
              </li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
