import React from "react";
import { useParams } from "react-router-dom";

import PageServices from "pages/Services.js";
import PageActivities from "pages/Activities.js";
import PageActivity from "pages/Activity.js";

import PageDestinations from "pages/Destinations.js";
import PageDestination from "pages/Destination.js";

import PageSignIn from "pages/SignIn.js";
import PageSignUp from "pages/SignUp.js";

import PrivacyPolicy from "pages/PrivacyPolicy.js";
import TermsOfService from "pages/TermsOfService.js";

export const components = {
  services: {
    component: PageServices,
    url: "/services",
  },

  activities: {
    component: PageActivities,
    url: "/activities",
  },
  activity: {
    subcomponent: {
      leisures: {
        component: PageActivity,
        activity: "leisures",
      },
      ticketing: {
        component: PageActivity,
        activity: "ticketing",
      },
      restauration: {
        component: PageActivity,
        activity: "restauration",
      },
    },
    url: "/activities",
  },

  destinations: {
    component: PageDestinations,
    url: "/destinations",
  },
  destination: {
    subcomponent: {
      spain: {
        component: PageDestination,
        country: "spain",
      },
      france: {
        component: PageDestination,
        country: "france",
      },
      italy: {
        component: PageDestination,
        country: "italy",
      },
      portugal: {
        component: PageDestination,
        country: "portugal",
      },
    },
    url: "/activities",
  },

  signin: {
    component: PageSignIn,
    url: "/signin",
  },
  signup: {
    component: PageSignUp,
    url: "/signup",
  },

  PrivacyPolicy: {
    component: PrivacyPolicy,
    url: "/privacypolicy",
  },
  TermsOfService: {
    component: TermsOfService,
    url: "/TermsOfService",
  },
};

export default () => {
  const { page, subpage } = useParams();

  try {
    let Component = null;
    //Component = components[page][subpage].component;
    if (subpage) {
      Component = components[page].subcomponent[subpage].component;
    } else {
      Component = components[page].component;
    }
    if (Component) return <Component />;

    throw new Error("Component Not Found");
  } catch (e) {
    //console.log(e);
    return <div>Error: Component Not Found</div>;
  }
};
