import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Hero.js";
import Welcome from "components/features/Welcome.js";
import Portfolio from "components/cards/Portfolio.js";
import Teams from "components/features/Teams.js";
import Testimonial from "components/testimonials/Testimonial.js";
import OursValues from "components/features/OursValues";
import FAQ from "components/faqs/Faq.js";
import Footer from "components/footers/Footer.js";

import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Welcome />
    <Teams />
    <Portfolio />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our <span tw="text-primary-500">clients</span> speak!
        </>
      }
      description="Listen to what our satisfied clients have to say about their experience with TRIPnBOX. Their testimonials tell the story of exceptional journeys, memorable events, and unique moments created. Discover how we turned their ideas into unforgettable memories!"
      testimonials={[
        {
          imageSrc: "/images/items/pic500x500_testimonial.png",
          profileImageSrc:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
          quote:
            "My recent experience with TRIPnBOX exceeded all my expectations! The dedicated team orchestrated a trip that perfectly matched my desires. From exceptional accommodations to carefully selected activities, every detail was thoughtfully planned to create an unforgettable adventure. TRIPnBOX doesn't just organize trips, they create unique experiences. I highly recommend their services to all travelers seeking personalization and excellence. Thank you to the entire team for making my trip a memorable experience with TRIPnBOX!",
          customerName: "Charlotte H. & Kevin B.",
          customerTitle: "4 days, Paris - Venice.",
        },
      ]}
      textOnLeft={true}
    />
    <OursValues />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">any questions?</span>
        </>
      }
    />
    <Footer />
  </AnimationRevealPage>
);
