import React from "react";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import OurDestination from "components/features/OurDestination.js";
import TabGrid from "components/cards/Items.js";
import Footer from "components/footers/Footer.js";

const Container = tw.div`relative`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const { subpage } = useParams();

  const cards = [
    {
      destination: "spain",
      country: "SPAIN",
      city: "Madrid, Barcelona, Valencia, Seville...",
      title: "Vibrant, Cultural, Sunny",
      description:
        "Spain, jewel of the Iberian Peninsula, enchants visitors with its diversity and irresistible charm. This Mediterranean country, bathed in sunshine, seduces with its golden beaches, vibrant cities, and rich cultural heritage. From the lively, cobbled streets of Barcelona to the artistic effervescence of Madrid, every corner of Spain tells a captivating story. The intense flavors of Spanish cuisine, the passion of flamenco, and iconic landmarks like the Alhambra in Granada add a unique dimension to this enchanting experience. Come and discover Spain, where each moment reveals an exquisite blend of tradition and modernity.",
      imageSrc: "/images/countries/country800x600_ES.png",
    },
    {
      destination: "france",
      country: "FRANCE",
      city: "Paris, Marseille, Bordeaux, Lyon...",
      title: "Romantic, Historic, Gastronomic",
      description:
        "France, the land of elegance and charm, dazzles visitors with its captivating diversity. From the cobblestone streets of Paris, the city of lovers, to the picturesque vineyards of the countryside, every corner of France reveals a rich history and timeless beauty. Iconic landmarks like the Eiffel Tower and the Louvre bear witness to its historical heritage, while delightful French cuisine invites to unforgettable feasts. From medieval hilltop villages to the sunny beaches of the French Riviera, France offers an enchanting experience where art, culture, and the joy of living harmoniously blend. Come and discover France, where every moment is a celebration of the art of French living.",
      imageSrc: "/images/countries/country800x600_FR.png",
    },
    {
      destination: "italy",
      country: "ITALY",
      city: "Rome, Milan, Naples, Venice...",
      title: "Artistic, Passionate, Picturesque",
      description:
        "Italy, the jewel of Europe, captivates with its artistic splendor, overflowing passion, and picturesque landscapes. From the majestic ruins of Rome to the romantic canals of Venice, every corner of Italy breathes history and art. The green hills of Tuscany, adorned with vineyards, add a touch of picturesque charm to this enchanting canvas. Italian passion, palpable in delicious cuisine and expressive gestures, brings every experience to life. Italy is a journey through the centuries, where art, romance, and gastronomy blend to create an unforgettable symphony. Come immerse yourself in the passionate soul of Italy, where every moment is a work of art.",
      imageSrc: "/images/countries/country800x600_IT.png",
    },
    {
      destination: "portugal",
      country: "PORTUGAL",
      city: "Lisbonne, Porto, Vila Nova de Gaia...",
      title: "Charming, Coastal, Cultural",
      description:
        "Portugal, the gem of the Iberian Peninsula, captivates with its enchanting charm, picturesque coastlines, and rich cultural heritage. From the cobblestone streets of Lisbon to the rolling vineyards of the Douro Valley, every corner of Portugal tells a warm and authentic story. The golden beaches of the Algarve offer an unforgettable seaside getaway, while medieval citadels in the hills add a touch of mystery to this enchanting landscape. Portuguese cuisine, with its Mediterranean flavors and seafood delights, delights the taste buds with an unparalleled gastronomic experience. Come discover Portugal, where warm hospitality and breathtaking landscapes welcome you at every turn, making every stay a memorable adventure.",
      imageSrc: "/images/countries/country800x600_PT.png",
    },
  ];

  const currentCard = cards.find((card) => card.destination === subpage);

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <OurDestination
          subheading={<Subheading>{currentCard.city}</Subheading>}
          heading={
            <>
              {currentCard.title}
              <wbr /> <HighlightedText>{currentCard.country}</HighlightedText>
            </>
          }
          description={<Description>{currentCard.description}</Description>}
          buttonRounded={false}
          textOnLeft={true}
          primaryButtonText="Latest Offers"
          imageSrc={currentCard.imageSrc}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        />

        <TabGrid
          heading={
            <>
              Checkout our <HighlightedText>menu.</HighlightedText>
            </>
          }
        />

        <SvgDotPattern1 />
        <SvgDotPattern2 />
        <SvgDotPattern3 />
        <SvgDotPattern4 />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
